import ReactGA4 from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
export const TrackGoogleAnalyticsEvent = (
  category: any,
  event_name: string | UaEventOptions,
  label: any,
  data: any
) => {
  console.log("GA event:", category, ":", event_name, ":", label);

  let event_params = {
    // category,
    // label,
    // event_modal:data,
    ...data,
  };
  // Send GA4 Event
  ReactGA4.event(event_name, event_params);
};
export const getReqJsonModalGA4 = (
  bussinessName: string,
  status: string,
  reason: string,
  apiurl: string,
  reqData?: any
) => {
  var jsonBody = {
    resto_name: bussinessName,
    status: status,
    reason: reason,
    apiurl: apiurl,
    reqData: reqData,
  };
  return jsonBody;
};
export const getReqJsonModalGA4WithoutReqData = (
  //   bussinessName: string,
  //   status: string,
  //   reason: string,
  //   apiurl: string,
  pageType: string,
  productType: string,
  reqData?: any
) => {
  var jsonBody = {
    pageType: pageType,
    productType: productType,
    // resto_name: bussinessName,
    // status: status,
    // reason: reason,
    // apiurl: apiurl,
    ecommerce: { ...reqData },
  };
  return jsonBody;
};
