import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import {
  getReqJsonModalGA4WithoutReqData,
  TrackGoogleAnalyticsEvent,
} from "../../helpers/GT4Helpers";
const { createSlice } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    addToCart(state, action) {
      console.log(action);
      const { translation, ...product } = action.payload;
      const cartItem = state.cartItems.find((item) => item.id === product.id);
      if (!cartItem) {
        state.cartItems.push({
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuidv4(),
        });
      } else {
        state.cartItems = state.cartItems.map((item) => {
          if (item.cartItemId === cartItem.cartItemId) {
            return {
              ...item,
              quantity: product.quantity
                ? item.quantity + product.quantity
                : item.quantity + 1,
              actual_price: product.price,
              is_discount: "No",
              is_for_whole_order: "No",
              single_item_name: "",
              single_item_price: product.price,
              single_options: null,
              multiple_option: [],
              description: { en: "", ar: "" },
              color_size: null,
              affiliation: "Direct",
              item_brand: "test-resto",
              item_category: "",
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
            };
          }
          return item;
        });
      }
      var jsonBody = getReqJsonModalGA4WithoutReqData(
        "product-page",
        "simple",
        {
          currency: "USD",
          value: 39,
          items: [
            {
              id: product.prdId,
              item_id: product.prdId,
              item_name: product.name,
              currency: "USD",
              price: product.price,
              item_category: "",
              item_category2: "",
              item_category3: "",
              quantity: product.quantity,
            },
          ],
        }
      );
      console.log(jsonBody);
      TrackGoogleAnalyticsEvent(
        "add_to_cart",
        "add_to_cart",
        "add_to_cart",
        jsonBody
      );
      toast.success(translation, {
        position: "top-center",
        toastId: "cart-success",
      });
    },
    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.cartItemId !== action.payload.cartItemId
      );
      toast.error(action.payload.translation, {
        position: "top-center",
        toastId: "cart-error",
      });
    },
    decreaseQuantity(state, action) {
      const { translation, ...product } = action.payload;
      if (product.quantity === 1) {
        state.cartItems = state.cartItems.filter(
          (item) => item.cartItemId !== product.cartItemId
        );
        toast.error(translation, { position: "top-center" });
      } else {
        state.cartItems = state.cartItems.map((item) =>
          item.cartItemId === product.cartItemId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );

        toast.warn(translation, {
          position: "top-center",
          toastId: "cart-decrement",
        });
      }
    },
    deleteAllFromCart(state) {
      state.cartItems = [];
    },
  },
});

export const {
  addToCart,
  deleteFromCart,
  decreaseQuantity,
  deleteAllFromCart,
} = cartSlice.actions;
export default cartSlice.reducer;
