import React, { Fragment, useEffect, useState } from "react";
import SEO from "../../components/seo";
import LayoutTwo from "../../layouts/LayoutTwo";
import HeroSliderThree from "../../wrappers/hero-slider/HeroSliderThree";
import CategoryOneSlider from "../../wrappers/category/CategoryOneSlider";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import TabProductThree from "../../wrappers/product/TabProductThree";
import BannerThree from "../../wrappers/banner/BannerThree";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { getUserPrefersLang } from "../../shared/helpersMethod";

const HomeElectronics = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { bussinessData } = useSelector((state: any) => state.bussinessData);

  // const { homePageCategories, getHomePageCategory, loading } =
  //   useGetHomeCategory();

  const curretnLang = getUserPrefersLang();

  useEffect(() => {
    if (curretnLang === "ar") {
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
  }, []);
  useEffect(() => {
    if (bussinessData) {
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [bussinessData]);

  return (
    <div className="scroll-x-none">
      {!loading && bussinessData && Object.keys(bussinessData).length !== 0 ? (
        <Fragment>
          <SEO
            title={
              bussinessData?.site_settings?.siteTitle !== ""
                ? bussinessData?.site_settings?.siteTitle
                : "Home"
            }
            titleTemplate="Home"
            description="home"
          />

          <LayoutTwo>
            <HeroSliderThree
              homePageSlider={
                bussinessData?.homepage_configurations?.homepage_slider?.slides
              }
            />

            {/* category slider */}
            {bussinessData?.homepage_configurations?.display_sections
              ?.display_featured_categories === "Yes" && (
              <CategoryOneSlider
                spaceBottomClass="pb-95 "
                topSpaceClass="pt-30"
                homePageCategories={
                  bussinessData?.homepage_configurations?.homepage_categories
                }
              />
            )}

            {/* section title with text */}
            <SectionTitleWithText
              spaceBottomClass=" pt-40"
              welcomeInfo={bussinessData?.homepage_configurations?.welcome_info}
            />
            {/* brand logo slider */}
            <BrandLogoSliderOne
              homePageBrandLogo={
                bussinessData?.homepage_configurations?.homepage_brands
              }
              spaceBottomClass="pb-20"
              spaceTopClass="pt-20"
            />
            <TabProductThree
              spaceBottomClass="pb-60"
              category="خلاط يدوي"
              homepageCarouselSlider={
                bussinessData?.homepage_configurations?.homepage_carousel_slider
              }
            />
            {/* banner */}
            <BannerThree
              bannerData={
                bussinessData?.homepage_configurations
                  ?.homepage_featured_product
              }
            />

            {/* testimonial */}
            {/* <TestimonialOne
              spaceTopClass="pt-100"
              spaceBottomClass="pb-95"
              spaceLeftClass="ml-70"
              spaceRightClass="mr-70"
              bgColorClass="bg-gray-3"
            /> */}

            {/* blog featured */}
            {/* <BlogFeaturedTwo spaceBottomClass="pb-55" /> */}
          </LayoutTwo>
        </Fragment>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default HomeElectronics;
