import React from "react";
export const useScrollLock = () => {
  // const scrollOffset = useRef<number>(null);
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = "hidden";
    // document.body.style.paddingRight = "17px";
    // @ts-ignore;
    // scrollOffset.current = window.pageYOffset;
    // document.body.style.position = "fixed";
    // document.body.style.top = `-${scrollOffset.current}px`;
    document.body.style.width = "100%";
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = "";
    // document.body.style.paddingRight = "";
    // document.body.style.position = "";
    // document.body.style.top = ``;
    document.body.style.width = "";
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};
