export const allLink = {
restoByNameForToken :`resto/by/name${'outletName'}`,
getBussinessData:'resto',
getCategories:'categories',
createCustomer:'create/customer?',
loadReceipent:'customer/recipients?',
loadAddress:'get/customer/address?',
saveAddress:'save/customer/address?',
saveGreetingMessage :'save/greeting/message?',
deleteAddress:'delete/customer/main/address?',
deleteReceiver:'`delete/recipient/${receiver_id}`',
placeOrder:'confirmed/order?',
getItemDetail :'`recipe?id=${itemId}`',
sendOtp :'send/otp?',
validOtp:'validate/otp?',


}
//  const URL_BASE_RJS = 'https://adminv1.meemorder.io/api/rjs/';
 const URL_BASE_RJS = 'rjs/';
//  /set/customer/main/address?
 export const apiVersion = 1;
 const URL_CONSTANTS = new Map<string, string>([
    ["URL_Get_Receipents", URL_BASE_RJS +"customer/recipients?"],
  
   
   //  ["URL_Get_TrackOrder", URL_BASE_RJS + `track/order?order=%s&red_if=%s`],
   //  ["URL_Get_TrackOrder1", URL_BASE_RJS + `track/order?order=8366&red_if=430`],
    ["URL_Bussienss_Recipe", URL_BASE_RJS + `business/recipe`],
    ["URL_Bussienss_Info", URL_BASE_RJS + `business/info`],
    ["URL_Bussienss_Categories", URL_BASE_RJS + `business/categories`],
    ["URL_Update_Address", URL_BASE_RJS + `set/customer/main/address?`],
    ["URL_GET,Customer_Discount", URL_BASE_RJS + `get/ordersForCustomerDiscountId?`],
    ["URL_PREORDER_CATEGORIES", URL_BASE_RJS + `business/pre/order/categories/items`],
    ["URL_POST_UPDATE_ORDER",URL_BASE_RJS + 'payment/success/update/status/order'],
    ["URL_DELETE_ADDRESS",URL_BASE_RJS + 'delete/customer/main/address?'],
    ["URL_GET_ORDER_RECEIPT",URL_BASE_RJS + 'get/order/details?'],
    ["URL_Get_Receipents", URL_BASE_RJS +"customer/recipients?"],
    ["URL_Send_Otp", URL_BASE_RJS + `send/otp?`],
    ["URL_Validate_Otp", URL_BASE_RJS + `validate/otp?`],
    //missing links 
    ["URL_Delete_Recipient",URL_BASE_RJS+`delete/recipient/`],
    ["URL_Confirmed_Order",URL_BASE_RJS+`confirmed/order?`],
    ["URL_Get_TrackOrder", URL_BASE_RJS + `track/order?`],
    ["URL_Create_Customer", URL_BASE_RJS + `create/customer?`],
    ["URL_Load_Recipients", URL_BASE_RJS + `customer/recipients?`],
    ["URL_Load_Address", URL_BASE_RJS + `get/customer/address?`],
    ["URL_Save_Address", URL_BASE_RJS + `save/customer/address`],
    ["URL_Save_Recipient", URL_BASE_RJS + `save/recipient?`],
    ["URL_Save_Greeting_Message", URL_BASE_RJS + `save/greeting/message?`],
    ["URL_Get_Bussiness_Token", URL_BASE_RJS + `business/by/name`],
    ["URL_GET_LOYALTY_POINTS", URL_BASE_RJS + `customer/loyalty/points?`],
  
    
   //  rjs/bussiness/by/name
   
   ]);
   export  {URL_CONSTANTS};
